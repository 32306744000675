<template>
    <form autocomplete="off" class="">
        <div class="row gy-3">
            <div class="col-12">
                <label class="form-label">
                    Status <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.status"
                name="status" placeholder="--select status--">
                    <option disabled value="">--select status--</option>
                    <option v-for="status in statusOptions" :key="status.id"
                        :value="status.value">{{status.label}}</option>
                </select>
                <div v-for="(item, index) in v$.data.status.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>  
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="update()"
                     type="button">Update</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: true
        }
    },
    data(){
        return{
            data: {
                status:"",
            },
            statusOptions:[
                {value: 'draft', label: 'Draft'},
                {value: 'pending', label: 'Pending'},
                {value: 'approved', label: 'Approved'},
                {value: 'rejected', label: 'Rejected'},
            ],
        }
    },
    validations() {
        return {        
            data:{
                status: { required: required},            
            }
        }
    },
    methods:{
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                const formData = new FormData()
                formData.append('data', JSON.stringify(this.data))
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/items/${this.editItem.id}/update`,formData)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>