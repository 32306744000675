
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Item overview</h5>
      <div class="card mb-5">
        <div class="card-body">
          <div class="row gy-3 justify-content-between align-items-start">
            <div class="col-10 col-sm-8">
              <span>Item ID</span>
              <h6> {{item.friendly_id}} </h6>
            </div>
            <div class="col-2 col-sm-4 text-sm-end">
              <div class="dropdown no-arrow">
                <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                  data-bs-toggle="dropdown" aria-expanded="false">
                  <i class="ri-more-2-fill"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <a @click.prevent="popupModalUpdate = true" class="dropdown-item" href="javascript:void(0)" >
                    Update status </a>
                  </li>
                  <li>
                    <router-link :to="`/subscriptions/${item.subscription_id}`" class="dropdown-item">
                    View subscription </router-link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row pt-3 g-2"> 
            <div class="col-6"> 
              Status <strong v-html="statusBadge(item.status)"> </strong>
            </div>
            <div class="col-sm-6"> 
                <span>Model Name:</span> <strong> {{item.device.model}} </strong>
            </div> 
            <div v-if="item.serial_number" class="col-sm-6"> 
                <span>Serial Number:</span> <strong> {{item.serial_number}} </strong>
            </div>
            <div v-if="item.imei_number" class="col-sm-6"> 
                <span>Imei Number:</span> <strong> {{item.imei_number}} </strong>
            </div>
            <div v-if="item.purchase_date" class="col-sm-6"> 
                <span>Purchase date:</span> <strong> {{$filters.date(item.purchase_date)}} </strong>
            </div>
            <div v-if="item.purchase_price" class="col-sm-6"> 
              <span>Price:</span> <strong> ${{$filters.money_format(item.purchase_price)}} </strong>
            </div>
            <div v-if="item.purchase_condition" class="col-sm-6"> 
              <span>Purchase condition:</span> <strong> {{item.purchase_condition.replace(/_/g, ' ')}} </strong>
            </div>
            <div v-if="item.purchase_place" class="col-sm-6"> 
              <span>Purchase location:</span> <strong> {{item.purchase_place}} </strong>
            </div>
            <div class="col-12"> 
              <hr>
              <h5>Device</h5>
              <div class="d-flex align-items-center justify-content-start">
                <div class="">
                  <img v-if="item.device.image" style="width:3rem; height:3rem; margin-right:1rem;" :src="absoluteUrl(item.device.image)" />
                  <div v-else class="avatar img-thumbnail me-2 flex-shrink-0">
                    <div class="avatar-title border bg-light text-primary rounded-circle text-uppercase">
                        {{item.device.model.charAt(0).toUpperCase()}}
                    </div>
                  </div>
                </div> 
                <div class="">
                    <h5 class="card-title mb-0">{{item.title}}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <h5 class="mb-3">Item photos</h5>
      <div class="card mb-5">
        <div class="card-body">
         <div class="row g-4">
            <div v-for="(file, fIndex) in item.files" :key="fIndex"
               class="col-12 col-lg-3 col-sm-6" >
              <div>
                <div @click="showImg(fIndex)" class="cursor-pointer">
                  <img :src="absoluteUrl(file.path)" class="mb-2 w-100" />
                </div>
                <span>{{file.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <vue-easy-lightbox v-if="item" :visible="lightbox.visible" :imgs="lightboxFiles"
        :index="lightbox.index" @hide="initializeAll()"></vue-easy-lightbox>
    </div>
  </div>
  <is-loading v-else />

  <b-modal hide-footer v-model="popupModalUpdate" title="Update claim">
    <item-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="item" @updateItem="updateItem($event)"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import VueEasyLightbox from "vue-easy-lightbox";
import ItemForm from '@/components/forms/ItemForm.vue'

export default {
  components:{
    IsLoading,
    VueEasyLightbox,
    ItemForm
  },
  data(){
    return {
      isLoading: true,
      item: null,
      lightbox:{
        index: null,
        visible: false,
      },
      popupModalUpdate: false,
    }
  },
  watch: {
    '$route.params.itemId': function (id){
      if(id) this.fetchItem()
    },
  },
  computed:{
    lightboxFiles(){
      return this.item.files.map(file => {
        if(file.is_video){
          return {
            title: file.description,
            thumb: this.absoluteUrl(file.path),
            mediaType: 'video',
            src: this.absoluteUrl(file.video_path) }
        }else{
          return {
            title: file.description,
            mediaType: 'image',
            src: this.absoluteUrl(file.path) }
        }
      })
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.item, item)
    },
    showImg(index) {
      this.lightbox = { index: index,visible: true};
    },
    fetchItem(){
      this.isLoading = true
      this.$http.get(`/items/${this.$route.params.itemId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.item = response.data.data
        }else{
          this.$router.push({path: "/error-404"})
        }
      })
    },
    initializeAll(){
      this.popupModalUpdate = false;
      this.lightbox = { index: null, visible:false}
    },
  },
  created(){
    this.fetchItem()
  }
}

</script>

<style lang="scss">

</style>
